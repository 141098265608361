<template>
  <div class="va-scroll-container" :style="`--va-0-scroll-color: ${String(scrollColor)};--va-1-scrollbar-size: ${String(scrollbarSize)};--va-2-overflow-x: ${String(overflowX)};--va-3-overflow-y: ${String(overflowY)};--va-4-scrollbar-position: ${String(scrollbarPosition)}`">
    <div class="va-scroll-container__content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

import { useColors, useSize, useSizeProps } from '../../composables'

export default defineComponent({
  name: 'VaScrollContainer',

  props: {
    ...useSizeProps,
    vertical: { type: Boolean, default: false },
    horizontal: { type: Boolean, default: false },
    color: { type: String, default: 'secondary' },
    rtl: { type: Boolean, default: false },
    gradient: { type: Boolean, default: false },
    sizesConfig: {
      type: Object,
      default: () => ({
        defaultSize: 4,
        sizes: { small: 4, medium: 6, large: 8 },
      }),
    },
    size: {
      type: String as PropType<'small' | 'medium' | 'large'>,
      default: 'small',
      validator: (v: string) => ['small', 'medium', 'large'].includes(v),
    },
  },

  setup (props) {
    const { getColor } = useColors()
    const { sizeComputed } = useSize(props)

    return {
      overflowX: computed(() => props.horizontal ? 'auto' : 'hidden'),
      overflowY: computed(() => props.vertical ? 'auto' : 'hidden'),
      scrollColor: computed(() => {
        const color = getColor(props.color)
        return props.gradient ? `linear-gradient(0deg, var(--va-scroll-container-scrollbar-gradient-to) 0%, ${color} 100%)` : color
      }),
      scrollbarSize: computed(() => sizeComputed.value),
      scrollbarPosition: computed(() => props.rtl ? 'rtl' : 'ltr'),
    }
  },
})
</script>

<style lang="scss">
@import '../../styles/resources';
@import './variables';

.va-scroll-container {
  @include va-scroll(var(--va-0-scroll-color), var(--va-1-scrollbar-size));

  overflow-x: var(--va-2-overflow-x);
  overflow-y: var(--va-3-overflow-y);
  width: 100%;
  height: 100%;
  direction: var(--va-4-scrollbar-position);

  &__content {
    height: stretch;
    width: stretch;
    direction: ltr;
  }
}
</style>
